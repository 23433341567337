import React, { useEffect, useRef, useState } from 'react'
import { searchResources } from '../classes/API'
import { Formik, Form, Field } from 'formik'
import DatePicker from "./Fields/DatePicker";
import TextInput from "./Fields/TextInput";
import Pagination from './Pagination/Pagination';
import _ from 'lodash'
import "react-datepicker/dist/react-datepicker.css";
import Josh from 'joshjs';

export default function resourceSearch ({filters=[]})
{
    const [resources, setResources] = useState([])
    const [count, setCount] = useState(0)
    const [pages, setPages] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)
    const [filtersOpen, setFiltersOpen] = useState(false)
    const initJoshJs = () => {
        window.josh = new Josh({
            initClass: "josh-js",
            offset: 0.2,
            animateInMobile: true,
            onDOMChange: true,
        })
    }


    useEffect(() => {
        (async () => {
            let response = await searchResources()
            let json = await response.json()
            setCount(json.data.count)
            setPages(json.data.max_pages)
            setPageNumber(json.data.current_page)
            setResources(json.data.resources)
            initJoshJs()
        })()
    }, []);

    function convertDateFormat(options){
        const opts = Object.assign({}, options);
        if('date-from' in opts){
            let dateVal = opts['date-from'];
            let timestamp = Math.round(dateVal.getTime()/1000);
            opts['date-from'] = timestamp;
        }
        if('date-to' in opts){
            let dateVal = opts['date-to'];
            let timestamp = Math.round(dateVal.getTime()/1000);
            opts['date-to'] = timestamp;
        }
        return opts;
    }

    async function handleFilterSubmit(options){
        let opts = convertDateFormat(options);
        let args = {}
        let scrollTarget = Math.floor(document.querySelector('.results-wrapper').getBoundingClientRect().top + window.pageYOffset - 50);
        _.each(opts, (option, key) => {
            args[key] = option
        })

        args['page'] = args['page'] ?? pageNumber;

        let response = await searchResources(args)
        let json = await response.json()
        setCount(json.data.count)
        setPages(json.data.max_pages)
        setPageNumber(json.data.current_page)
        setResources(json.data.resources)
        setFiltersOpen(false)
        // reinit joshJs after srollTo end
        const onScroll = _.throttle(function () {
            if (Math.floor(window.pageYOffset) === scrollTarget) {
                window.removeEventListener('scroll', onScroll)
                initJoshJs()
            }
        }, 100)
        window.addEventListener('scroll', onScroll)
        // scroll to top
        window.scrollTo({ top: scrollTarget, behavior: 'smooth' })
    }

    document.addEventListener('click', function(e) {
        let filters = document.querySelector('.filters');
        let button = document.querySelector('.filter-btn');
        if (!filters.contains(e.target) && !button.contains(e.target)) {
            setFiltersOpen(false);
        }
    });

    function initComponent(){
        let minHeight = document.querySelector('.filters').clientHeight;
        document.querySelector(".results-wrapper").style.minHeight = (minHeight + 0) + "px";
    }
    window.setTimeout(function(){
        initComponent();
    });

    return (
        <div id="resource-search" className={ filtersOpen ? 'filters-open' : 'filters-closed' }>
            <Formik initialValues={{}} onSubmit={(values) => handleFilterSubmit(values)} onReset={() => handleFilterSubmit({})}>
                {({handleSubmit, values, setFieldValue, handleReset}) => (
                <Form>
                    <div className="flex items-start mb-8 lg:mb-12">
                        <div className="results-wrapper relative w-full">
                            <Search options={filters} setPageNumber={setPageNumber} handleSubmit={handleSubmit} />
                            <Resources filters={filters} values={values} resources={resources} count={count} pages={pages} pageNumber={pageNumber} setPageNumber={setPageNumber} handleSubmit={handleSubmit} handleReset={handleReset} filtersOpen={filtersOpen} setFiltersOpen={setFiltersOpen} handleFilterSubmit={handleFilterSubmit} setFieldValue={setFieldValue} />
                        </div>
                    </div>
                </Form>
                )}
            </Formik>
        </div>
    )
}

function Filters({options, setPageNumber, handleSubmit, values, handleReset, setFiltersOpen}){
    return (
        <div className="filters w-4/5 lg:w-1/3 absolute px-6 lg:px-20 pr-6 lg:pr-0 py-8 lg:py-12 bg-gray-100">
            <h2 className="type-preset-5 font-bold mb-6 pl-20 lg:pl-0">Filter Resources By:</h2>
            <section className="space-y-10 mb-8">
                {Object.entries(options).map(filterGroup => {
                    if(filterGroup[1]['type'] == 'checkbox'){
                        return (<FilterGroup key={filterGroup[0]} filterGroup={filterGroup} />)
                    } else if(filterGroup[1]['type'] == 'radio'){
                        return (<RadioGroup key={filterGroup[0]} radioGroup={filterGroup} />)
                    } else if(filterGroup[1]['type'] == 'datepicker'){
                        return (<DateGroup key={filterGroup[0]} dateGroup={filterGroup} />)
                    }
                })}
            </section>
            <div className="block lg:flex lg:space-x-6 space-y-6 lg:space-y-0">
                <button type="button" className="block btn btn--orange" onClick={
                    () => {
                        setPageNumber(1);
                        handleSubmit();
                    }
                }>
                    <span
                        className="btn__text josh-js"
                        data-josh-anim-name="buttonTextIn"
                        data-josh-duration="350ms"
                        data-josh-delay="600ms"
                    >Apply Filters</span>
                    <span 
                        className="btn__background josh-js"
                        data-josh-anim-name="buttonBackgroundIn"
                        data-josh-duration="600ms"
                    ></span>
                </button>
                <button 
                    type="button"
                    className="block btn-link btn-link--link type-preset-7 font-bold text-orange-300 self-center josh-js"
                    data-josh-anim-name="fadeIn"
                    data-josh-duration="350ms"
                    data-josh-delay="900ms"
                    onClick={
                    () => {
                        setPageNumber(1);
                        handleReset();
                    }
                }>Reset All Filters</button>
            </div>
        </div>
    )
}

function FilterGroup({filterGroup}){
    return (
        <div>
            <h3 className="type-preset-6 text-blue-500 font-bold mb-4">{filterGroup[0]}</h3>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-2 gap-y-4">
                {Object.entries(filterGroup[1]['options']).map(checkbox => (
                    <label className="type-preset-7 text-ncel-black font-display flex items-center" key={checkbox[0]}>
                      <Field className="form-checkbox h-5 w-5 text-blue-500 hover:text-blue-100 border-2 border-blue-100 mr-2" type="checkbox" name={filterGroup[1]['name']} value={checkbox[0]} />
                      {checkbox[1]}
                    </label>
                ))}
            </div>
        </div>
    )
}

function RadioGroup({radioGroup}){
    return (
        <div>
            <h3 className="type-preset-6 text-blue-500 font-bold mb-4">{radioGroup[0]}</h3>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-2 gap-y-4">
                {Object.entries(radioGroup[1]['options']).map(radio => (
                    <label className="type-preset-7 text-ncel-black font-display flex items-center" key={radio[0]}>
                      <Field className="form-checkbox h-5 w-5 text-blue-500 hover:text-blue-100 border-2 border-blue-100 mr-2" type="radio" name={radioGroup[1]['name']} value={radio[0]} />
                      {radio[1]}
                    </label>
                ))}
            </div>
        </div>
    )
}

function DateGroup({dateGroup}){
    return (
        <div>
            <h3 className="type-preset-6 text-blue-500 font-bold mb-4">{dateGroup[0]}</h3>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-2 gap-y-4">
                {Object.entries(dateGroup[1]['options']).map(datepicker => {
                    let field_name = "date-"+(datepicker[1]).toLocaleLowerCase();
                    return (
                        <div key={field_name} className="type-preset-7 text-blue-500 font-display block mr-10 pb-2 border-b border-blue-100">
                            <label>
                                {datepicker[1]}
                            </label>
                            <DatePicker name={field_name} placeholderText="Choose Date" />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

function Search({options, setPageNumber, handleSubmit, setFieldValue}){
    return (
        <div className="search-container px-6 lg:px-20 py-8 lg:py-12 bg-blue-400">
            <div className="flex">
                <div className="w-full">
                    <TextInput name={options['Search']['name']} placeholder="Search Our Research Center" />
                </div>
                <button type="submit" className="btn bg-orange-300 type-preset-7 font-bold text-ncel-white hover:bg-orange-500 focus:outline-none" onClick={
                    () => {
                        setPageNumber(1);
                        handleSubmit()
                    }
                }>Search</button>
            </div>
        </div>
    )
}

function Resources({filters, values, resources, count, pages, pageNumber, setPageNumber, handleSubmit, handleReset, filtersOpen, setFiltersOpen, handleFilterSubmit, setFieldValue}){
    return (
        <div className="results bg-blue-500 px-6 lg:px-20 pt-8 lg:pt-12 pb-8 lg:pb-12">
            <div className="relative lg:static filter-wrapper">
                <div>
                    <Filters options={filters} values={values} setPageNumber={setPageNumber} handleSubmit={handleSubmit} handleReset={handleReset} setFiltersOpen={setFiltersOpen} />
                </div>
            </div>
            <button
            type="button"
            className="filter-btn block absolute lg:hidden btn bg-gray-100 text-blue-500 type-preset-7 -ml-6 py-3 pl-6 pr-10"
            onClick={
                () => {
                    setFiltersOpen(!filtersOpen);
                }
            }
            >Filter <span></span></button>
            <h2 className="type-preset-5 text-ncel-white font-display mb-6 pl-24 lg:pl-0">Found {count} Results</h2>
            <div className="resources-list space-y-10">
                {resources.map(resource => (
                    <Resource key={resource.ID} model={resource} setPageNumber={setPageNumber} setFieldValue={setFieldValue} handleSubmit={handleFilterSubmit} />
                ))}
            </div>
            <Paginate pages={pages} pageNumber={pageNumber} setPageNumber={setPageNumber} handleSubmit={handleSubmit} />
        </div>
    )
}

function Resource({model, setPageNumber, setFieldValue, handleSubmit}){
    return (
        <article className="space-y-4 bg-ncel-white p-10">
            <h4 className="type-preset-6 text-blue-500 font-bold">{model.type}</h4>
            <h3 className="type-preset-4 text-ncel-black font-display">{model.title.replace('&#038;','&').replace('&#215;', 'x').replace('&#8211;', '-')}</h3>
            <div className="type-preset-7 text-gray-300 font-display" dangerouslySetInnerHTML={{__html: (model.author ? 'by ' + model.author + ' &bull; ' : '') + model.date}} />
            <p className="type-preset-6 text-ncel-black font-display">{model.overview}</p>
            <div className="flex space-x-6 py-4">
                {model.download ? (
                    <a 
                        href={model.download} 
                        className="btn btn--orange flex items-center"
                        download
                    >
                        <span 
                            className="btn__text josh-js"
                            data-josh-anim-name="buttonTextIn"
                            data-josh-duration="350ms"
                            data-josh-delay="600ms"
                        >Download</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="13" className="ml-1"><path d="M11 7.065l-5.5 5.5-5.5-5.5L1.065 6 4.75 9.685V0h1.5v9.687L9.935 6 11 7.065z" fill="#FFF" fillRule="evenodd"/></svg>
                        <span 
                            className="btn__background josh-js"
                            data-josh-anim-name="buttonBackgroundIn"
                            data-josh-duration="600ms"
                        ></span>
                    </a>)
                : ''}
                <a 
                    href={model.link}
                    className="btn btn--border-gap-orange josh-js"
                    data-josh-anim-name="showBorder"
                    data-josh-duration="300ms"
                    data-josh-delay="300ms"
                >   
                    <span
                        className="btn__text josh-js"
                        data-josh-anim-name="buttonTextIn"
                        data-josh-duration="350ms"
                        data-josh-delay="600ms"
                    >View Summary</span>
                    <span 
                        className="btn__background josh-js"
                        data-josh-anim-name="buttonBackgroundIn"
                        data-josh-duration="600ms"
                    ></span>
                </a>
            </div>
            <div className="type-preset-7 font-display">
                {model.issues && model.issues.map(issue => (
                    <IssueTag key={issue.id} id={issue.id} name={issue.name} setPageNumber={setPageNumber} setFieldValue={setFieldValue} handleSubmit={handleSubmit} />
                ))}
            </div>
        </article>
    )
}

function IssueTag({id, name, setPageNumber, setFieldValue, handleSubmit}){
    return (
        <a href={'#'+id} className="transition-all duration-300 text-blue-500 hover:text-orange-300 mr-2" onClick={
            (e) => {
                e.preventDefault();
                setPageNumber(1);
                setFieldValue('issue-type', id);
                setFieldValue('search', '');
                let options = {'page': 1, 'issue-type': [''+id]};
                handleSubmit(options).then(() => {
                    let filters = document.querySelectorAll("input[name='issue-type']");
                    _.each(filters, (filter) => {
                        filter.checked = (filter.value == id);
                    });
                    document.querySelector("input[name='search']").value = '';
                });
            }
        }>{name}</a>
    )
}

function Paginate({pages, pageNumber, setPageNumber, handleSubmit}){
    if(pages > 1){
        return (
            <div className='pagination mt-10'>
                <Pagination
                    total={pages}
                    prevText="Prev"
                    nextText="Next"
                    endSize={1}
                    midSize={4}
                    current={parseInt(pageNumber)}
                    onClickPage={(pageNum) => {
                        setPageNumber(pageNum);
                        handleSubmit();
                    }}
                />
            </div>
        )
    } else {return (<div />)}
}
