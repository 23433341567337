import React, { useEffect, useRef, useState } from 'react'
import { searchGlossary } from '../classes/GlossaryAPI'
import { Formik, Form, Field } from 'formik'
import TextInput from "./Fields/TextInput";
import _ from 'lodash'

export default function GlossaryHub ()
{
    const [items, setItems] = useState([])

    function isActive(element) {
        const rect = element.getBoundingClientRect();
        const anchorLink = document.querySelector("a[href='#" + element.id + "']");
        if(rect.top < 150 && rect.bottom > 150) {
            anchorLink.classList.remove("text-gray-300");
            anchorLink.classList.add("text-orange-300");
        } else {
            anchorLink.classList.remove("text-orange-300");
            anchorLink.classList.add("text-gray-300");
        }

    }

    const headerHeight = document.querySelector(".site-header").offsetHeight;

    window.onscroll = function() {
        const parentContainer = document.querySelector(".glossary-items-list");
        const scrollCheck = parentContainer.getBoundingClientRect();
        const nav = document.querySelector(".anchor-container");
        const glossaryGroup = document.querySelectorAll(".glossary-group");

        if (scrollCheck.top < headerHeight && scrollCheck.bottom > (nav.offsetHeight * 6)) {
            nav.classList.add("js-anchor-nav");
            parentContainer.style.marginTop = nav.offsetHeight + 'px';
            nav.style.marginTop = headerHeight + 'px';
            glossaryGroup.forEach(el => isActive(el));
        }
        else if(scrollCheck.top > headerHeight || scrollCheck.bottom < (nav.offsetHeight * 3)){
            nav.classList.remove("js-anchor-nav");
            parentContainer.style.marginTop = 0;
            nav.style.marginTop = 0;
        }
    }

    let searchParams = {};
    useEffect(() => {
        (async () => {
            let response = await searchGlossary(searchParams)
            let json = await response.json()
            setItems(json.data.items)
        })()
    }, []);

    async function handleFilterSubmit(opts){

        let args = {}
        _.each(opts, (option, key) => {
            args[key] = option
        })

        let response = await searchGlossary(args)
        let json = await response.json()
        setItems(json.data.items)
    }

    return (
        <div id="glossary-search" className="glossary-hub">
            <Formik initialValues={{}} onSubmit={(values) => handleFilterSubmit(values)}>
                {({handleSubmit}) => (
                <Form>
                    <div className="news-hub mb-12 mt-spacing-13 lg:mt-spacing-29">
                        <Search handleSubmit={handleSubmit} />
                        <GlossaryItems items={items} />
                    </div>
                </Form>
                )}
            </Formik>
        </div>
    )
}

function Search({ handleSubmit }){
    return (
        <div className="search-glossary-container md:container mx-auto relative mb-12">
            <h3 className="type-preset-3 ml-6 md:ml-0 text-blue-500 mb-4">Search for a Term</h3>
            <div className="search-inner-container mx-auto px-6 py-12 bg-blue-300">
                <div className="flex">
                    <div className="w-full">
                        <TextInput name={'Search'} className="text-gray-300" placeholder="Enter a term, such as &quot;carbon pricing&quot;." />
                    </div>
                    <button type="button" className="btn bg-orange-300 type-preset-7 font-bold text-ncel-white border border-orange-300 hover:bg-orange-500 hover:text-ncel-white focus:outline-none hover:outline-none hover:border-orange-500" onClick={handleSubmit}>Search</button>
                </div>
            </div>
        </div>
    )
}

function AnchorNav({items}){
    return (
        <div className="anchor-container container mx-auto inset-x-0">
            <div className="anchor-items bg-gray-100 px-0 flex flex-row justify-evenly md:justify-start flex-wrap">
                <span className="bg-blue-500 px-6 py-3 type-preset-6 font-display text-blue-100 hidden md:inline-block">
                    Jump to:
                </span>
                {Object.keys(items).map(group => (
                    <div key={group} className="py-3 md:pl-6">
                        <a className="type-preset-6 transition-colors duration-300 text-gray-300 hover:text-orange-300" href={'#group-' + group}>{group}</a>
                    </div>
                ))}
            </div>
        </div>
    )
}

function GlossaryItems({items}){
    return (
        <div className="glossary-items-list">
            <AnchorNav items={items} />
            <div className="container mx-auto flex flex-wrap flex-col glossary-container">
                {Object.entries(items).map((group) => (
                    <GlossaryGroup group={group} />
                ))}
            </div>
        </div>
    )
}

function GlossaryGroup({group}){
    return (
        <div id={'group-' + group[0]} key={group[0]} className="glossary-group">
            <h3 className="type-preset-3 text-blue-500 mb-8" key={group[0]}>{group[0]}</h3>
            {group[1].map(item => (
                <GlossaryTerm key={item.ID} model={item} />
            ))}
        </div>
    )
}

function GlossaryTerm({model}){
    return (
        <div className="glossary-term w-full: lg:w-2/3 mb-8" id={'term-' + model.slug}>
            <h6 className="type-preset-5 text-blue-300 font-bold mb-2">{model.title}</h6>
            <p className="type-preset-6 text-ncel-black font-display mb-4">{model.definition}</p>
            {model.related.length > 0 &&
                <div className="type-preset-7 text-blue-300 font-display">
                    <strong>Related Terms:</strong> {model.related.map(related => (
                        <a href={'#term-' + related.slug} className="pl-2 transition-colors duration-300 hover:text-orange-300">{related.title}</a>
                    ))}
                </div>
            }
        </div>
    )
}
