import React, {useEffect, useState} from 'react';
import superagent from 'superagent';
import { searchIssues } from '../classes/IssuesAPI';
import { Formik, Form, Field } from 'formik'

import _ from 'lodash';

function fetchIssues (issueType)
{   
    let url = `/wp-json/wp/ncel/v2/issue`;
    // url += `&issue-type=${issueType.slug}`;
    return superagent.get(url);

}

export default function IssueArchive ()
{
    const [issues, setIssues] = useState([])
    const [issueTypes, setIssueType] = useState()
    const [activeTypes, setActiveTypes] = useState([])
    const [isOpened, setIsOpened] = useState(false)
    const [filtersOpen, setFiltersOpen] = useState(false)

    useEffect(() => {
        (async () => {
            let val = (new URLSearchParams(window.location.search)).get("issue-type")
            let params = (val === undefined || val == null || val.length <= 0) ? {} : {'issue_type': [val]} 
            let response = await searchIssues(params)
            let json = await response.json()
            setIssues(json.data)
            setIssueType(json.issue_types)
        })()
    }, []);

    function toggle() {
        setIsOpened(wasOpened => !wasOpened);
    }

    async function handleFilterSubmit(options){
        let args = {issue_type: []}

        _.each(options, (option, key) => {
            if(option.length) args['issue_type'].push(option)
        })
        let response = await searchIssues(args)
        let json = await response.json()
        setIssues(json.data)
        setActiveTypes(args['issue_type'])
    }

    return (
        <div id="issue-search" className="issue-archive pt-spacing-29">
            <div data-tabs className="relative">
                <div>
                <Formik initialValues={{}} onSubmit={(values) => handleFilterSubmit(values)} onReset={() => handleFilterSubmit({})}>
                    {({handleSubmit, values, setFieldValue, handleReset}) => (
                    <Form>
                        <div className="flex items-start mb-8 lg:mb-12">
                            <div className="relative w-full">
                                <Issues filters={issueTypes} activeTypes={activeTypes} values={values} issueTypes={issueTypes} issues={issues} handleSubmit={handleSubmit} handleReset={handleReset} filtersOpen={filtersOpen} setFiltersOpen={setFiltersOpen} toggle={toggle} isOpened={isOpened} setFieldValue={setFieldValue}/>
                            </div>
                        </div>
                    </Form>
                    )}
                </Formik>
                </div>
            </div>
        </div>
    )
}


function Filters({options, handleSubmit, values, handleReset, setFiltersOpen, issueTypes}){
    return (
            <div id="filter-box" className="relative filter-box w-full">
                <div className="inner-container site-inner-container mx-auto bg-blue-500 pb-10">
                    <div className="bg-blue-500 filter-box-bg"></div>
                    <h5 className="type-preset-5 text-ncel-white font-bold mb-spacing-4">Issue</h5>
                    <div className="issue-type-checkboxes flex flex-wrap justify-start mb-spacing-9">
                        {options && Object.entries(options).map(checkbox => (
                            <label className="issue-type relative type-preset-7 text-ncel-white font-display flex items-center" key={checkbox[0]}>
                              <Field className="form-checkbox h-5 w-5 text-blue-100 border-2 border-blue-100 mr-2" type="checkbox" name={checkbox[1]['slug']} value={checkbox[1]['slug']} />
                              {checkbox[1]['name']}
                            </label>
                        ))}

                    </div>
                    <div className="submit-reset-buttons flex flex-col lg:flex-row justify-end pr-10">
                        <button type="button" className="apply-button type-preset-7 inline-block btn white-orange-btn" onClick={
                            () => {
                                handleSubmit();
                            }
                        }>Apply Filters</button>
                        <button type="button" className="submit-button type-preset-7 inline-block btn white-orange-btn" onClick={
                            () => {
                                handleReset();
                            }
                        }>Reset All Filters</button>
                    </div>
                </div>
            </div>
    )
}

function Issues({filters, values, issues, handleSubmit, handleReset, filtersOpen, setFiltersOpen, toggle, issueTypes, isOpened, setFieldValue, activeTypes}){

    function removeFieldAndSubmit(slug) {
        setFieldValue(slug, 0);
        handleSubmit();
    }
    return (
        <div className="issues-filter" data={isOpened ? "filter-box-is-open" : "filter-box-is-closed"}>
            <div className="issue-bar relative overflow-hidden">
                <div className="issue-bar-container px-0 site-inner-container mx-auto bg-blue-500 text-ncel-white flex justify-between">
                    <div className="issues-total py-spacing-6">
                        {issues && issues.length != 0 && (
                        <span className="type-preset-5">
                            Viewing {issues.length} {issues.length === 1 ? 'Issue' : 'Issues'}
                        </span>
                        )}
                    </div>

                <button id="filter-button" type="button" onClick={toggle} className="filter-button flex items-center p-6 lg:px-4 lg:py-spacing-6">
                    <span className="type-preset-5 mr-4 hidden lg:block">Filter</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24">
                        <g fill="#FFF" fillRule="nonzero">
                            <path d="M12.25 3.09v1.5H.75v-1.5zm10 0v1.5h-5.5v-1.5zm-16 8v1.5H.75v-1.5zm16 0v1.5h-11.5v-1.5zm-15 8v1.5H.75v-1.5zm15 0v1.5h-10.5v-1.5z"/><path d="M17.418.922v5.5h-1.5v-5.5zm-11 8v5.5h-1.5v-5.5zm6 8v5.5h-1.5v-5.5z"/>
                        </g>
                    </svg>
                </button>

            </div>
            {/* {isOpened && ( */}
            <Filters options={filters} values={values} handleSubmit={handleSubmit} handleReset={handleReset} setFiltersOpen={setFiltersOpen} issueTypes={issueTypes} />
            {/* )} */}
            <div className="issues-posts container mx-auto pt-spacing-22">
                <div className="issues-posts-filter-links mb-8">
                    {activeTypes.length > 0 &&
                        <>
                            {activeTypes.map((type, idx) => (<React.Fragment key={idx + type}>
                                <button type="button" className="mr-4 mb-4 text-orange-300 type-preset-7 hover:text-orange-500" onClick={() => removeFieldAndSubmit(type)}>
                                    {type}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                                        <path d="M0 0.75H11V2.25H0z" transform="translate(-332.000000, -1126.000000) translate(229.000000, 1119.000000) translate(108.500000, 12.908948) rotate(-360.000000) translate(-108.500000, -12.908948) translate(100.000000, 4.408948) translate(3.843146, 3.257359) translate(4.949747, 4.949747) rotate(-45.000000) translate(-4.949747, -4.949747) translate(-0.550253, 3.449747)"/>
                                        <path d="M0 0.75H11V2.25H0z" transform="translate(-332.000000, -1126.000000) translate(229.000000, 1119.000000) translate(108.500000, 12.908948) rotate(-360.000000) translate(-108.500000, -12.908948) translate(100.000000, 4.408948) translate(3.843146, 3.257359) translate(4.949747, 4.949747) scale(-1, 1) rotate(-45.000000) translate(-4.949747, -4.949747) translate(-0.550253, 3.449747)"/>
                                    </svg>
                                </button>
                            </React.Fragment>))}
                            <button type="button" className="mb-4 text-orange-300 type-preset-7 hover:text-orange-500" onClick={() => handleReset()}>
                                Clear All Filters
                                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                                    <path d="M0 0.75H11V2.25H0z" transform="translate(-332.000000, -1126.000000) translate(229.000000, 1119.000000) translate(108.500000, 12.908948) rotate(-360.000000) translate(-108.500000, -12.908948) translate(100.000000, 4.408948) translate(3.843146, 3.257359) translate(4.949747, 4.949747) rotate(-45.000000) translate(-4.949747, -4.949747) translate(-0.550253, 3.449747)"/>
                                    <path d="M0 0.75H11V2.25H0z" transform="translate(-332.000000, -1126.000000) translate(229.000000, 1119.000000) translate(108.500000, 12.908948) rotate(-360.000000) translate(-108.500000, -12.908948) translate(100.000000, 4.408948) translate(3.843146, 3.257359) translate(4.949747, 4.949747) scale(-1, 1) rotate(-45.000000) translate(-4.949747, -4.949747) translate(-0.550253, 3.449747)"/>
                                </svg>
                            </button>
                        </>
                    }
                </div>
                {issues && issues.map(issue => (
                    <IssueCard issue={issue} key={issue.id} />
                ))}
            </div>
        </div>
        </div>
    )
}

function IssueCard({issue}){
    return (
        <a href={issue.link} className="single-issue josh-js" data-josh-anim-name="fadeInUp" data-josh-duration="500ms">
            <div className="image overflow-hidden mb-spacing-3">
                <img src={issue.image_url ? issue.image_medium : ''} alt={issue.name} loading="lazy"></img>
            </div>
            <h4 className="type-preset-5" dangerouslySetInnerHTML={{__html: issue.name }} />
        </a>
    )
}
