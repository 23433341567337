require('alpinejs')
require('d3')
require('./classes/luminateExtend.js')
import React from 'react';
import ReactDOM from 'react-dom'
import ReactComponentLoader from './classes/ReactComponentLoader';
import NewsHub from './components/NewsHub';
import ArticlesHub from './components/ArticlesHub';
import SuccessStories from './components/SuccessStories';
import GlossaryHub from './components/GlossaryHub';
import NewsFilter from './components/NewsFilter';
import IssueArchive from './components/IssueArchive';
import Accordion from './components/Accordion';
import OnPageNav from './onPageNavLinks';
import FadeIn from './components/FadeIn';
import CustomEmbed from './components/CustomEmbed';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'slick-carousel';
import $ from 'jquery';
import Josh from 'joshjs';
import Rellax from 'rellax';
import './onPageNavLinks';
import './resourcesTeaserPagination';
import './newsEventsSticky';

ReactComponentLoader({
  'loadingIndicator': require('./components/LoadingIndicator').default,
  'resourceSearch': require('./components/resourceSearch').default,
  'globalSearchResults': require('./components/GlobalSearchResults').default,
  'successStories': require('./components/SuccessStories').default,
})

document.querySelectorAll('[data-react-issue-archive]').forEach((elm) => {
  ReactDOM.render(<IssueArchive />, elm)
});

document.querySelectorAll('[data-react-news-hub]').forEach((elm) => {
	ReactDOM.render(<NewsHub />, elm)
});

document.querySelectorAll('[data-react-articles-hub]').forEach((elm) => {
  let articleType = elm.getAttribute('article-type');
	ReactDOM.render(<ArticlesHub articleType={articleType} />, elm)
});

document.querySelectorAll('[data-react-success-stories]').forEach((elm) => {
	ReactDOM.render(<SuccessStories />, elm)
});

document.querySelectorAll('[data-react-glossary-hub]').forEach((elm) => {
	ReactDOM.render(<GlossaryHub />, elm)
});

document.querySelectorAll('[data-react-news-filter]').forEach((elm) => {
	ReactDOM.render(<NewsFilter />, elm)
});

/** Vanilla JS components */
// new (require('./components/SiteHeader').default);


//mobile menu
$(".mobile-menu-toggle").click(function(){
	$('.mobile-menu-wrapper').toggleClass('open');
	$('.mobile-menu-outer-wrapper').toggleClass('open');
	$('.mobile-menu-toggle').toggleClass('active');
});


const mobileMenuToggle = document.querySelector('.mobile-menu-toggle')
const mobileMenuClose = document.querySelector('.mobile-menu-close')
const mobileMenu = document.querySelector('.mobile-menu-wrapper')
const mobileMenuOuterWrapper = document.querySelector('.mobile-menu-outer-wrapper')
const mobileSubmenu = document.querySelectorAll(
  '#mobile-menu ul.sub-menu'
)
const mobileLis = document.querySelectorAll(
  "#mobile-menu > li.menu-item-has-children"
);
mobileMenuToggle.addEventListener(
  'click',
  e => {
    mobileMenu.classList.add('open')
    mobileMenuOuterWrapper.classList.add('open')
    mobileMenuToggle.classList.add('active')
  },
  false
)
mobileLis.forEach(mobileLi => {
  jQuery(mobileLi).click(function(e) {
    $(mobileLis).not(this).each(function() {
      if ($(this).hasClass('open')) {
        $(this).removeClass('open');
        $('> .sub-menu', this).slideUp(400);
      }
    });
    $(this).toggleClass('open')
    jQuery('> .sub-menu', this).stop().slideToggle(400);
    // jQuery(mobileSubmenu).stop().slideUp(400, function() {
    //     jQuery('.sub-menu', $this).stop().slideToggle(400);
    // });
  })
});

document.addEventListener(
  'click',
  e => {
    e = e || window.event
    var target = e.target || e.srcElement
    if (target.closest('.mobile-menu-close') !== null) {
      mobileMenu.classList.remove('open')
      mobileMenuOuterWrapper.classList.remove('open')
      mobileMenuToggle.classList.remove('active')
    }
  },
  false
)

// ;( function( window ) {

  // http://stackoverflow.com/a/11381730/989439
  // function mobilecheck() {
  //  var check = false;
  //  (function(a){if(/(android|ipad|playbook|silk|bbd+|meego).+mobile|avantgo|bada/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)/|plucker|pocket|psp|series(4|6)0|symbian|treo|up.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0,4)))check = true})(navigator.userAgent||navigator.vendor||window.opera);
  //  return check;
  // }

  // http://www.jonathantneal.com/blog/polyfills-and-prototypes/
  // !String.prototype.trim && (String.prototype.trim = function() {
  //  return this.replace(/^s+|s+$/g, '');
  // });

  function UISearch( el, options ) {
    this.el = el;
    this.inputEl = el.querySelector( 'form > input.sb-search-input' );
    this._initEvents();
  }

  UISearch.prototype = {
    _initEvents : function() {
      var self = this,
        initSearchFn = function( ev ) {
          ev.stopPropagation();
          // trim its value
          self.inputEl.value = self.inputEl.value.trim();

          if( !classie.has( self.el, 'sb-search-open' ) ) { // open it
            ev.preventDefault();
            self.open();
          }
          else if( classie.has( self.el, 'sb-search-open' ) && /^s*$/.test( self.inputEl.value ) ) { // close it
            ev.preventDefault();
            self.close();
          }
        }

      this.el.addEventListener( 'click', initSearchFn );
      this.el.addEventListener( 'touchstart', initSearchFn );
      this.inputEl.addEventListener( 'click', function( ev ) { ev.stopPropagation(); });
      this.inputEl.addEventListener( 'touchstart', function( ev ) { ev.stopPropagation(); } );
    },
    open : function() {
      var self = this;
      classie.add( this.el, 'sb-search-open' );
      // focus the input
      // if( !mobilecheck() ) {
      //  this.inputEl.focus();
      // }
      // close the search input if body is clicked
      var bodyFn = function( ev ) {
        self.close();
        this.removeEventListener( 'click', bodyFn );
        this.removeEventListener( 'touchstart', bodyFn );
      };
      document.addEventListener( 'click', bodyFn );
      document.addEventListener( 'touchstart', bodyFn );
    },
    close : function() {
      this.inputEl.blur();
      classie.remove( this.el, 'sb-search-open' );
    }
  }

  // add to global namespace
  window.UISearch = UISearch;

// } )( window )

$(document).ready(function(){
  $('.large-issue-carousel__slider--inner').slick({
    dots: true,
    arrows: true,
    draggable: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    appendArrows: '#large-issue-carousel-slider-arrows-container',
    prevArrow: '.prev-large-issue-carousel-slider-arrow',
    nextArrow: '.next-large-issue-carousel-slider-arrow',
    appendDots: $(this).find('.large-issue-carousel-dots'),
    adaptiveHeight: true,
    customPaging: function(slider, i) {
      const slide = $(slider.$slides[i]);
      const term = slide.find('.text-content').data('termname');
      return `<a class="dot-name" href="javascript:void(0);"><span>${term}</span></a>`;
    },
    responsive: [
      {
      breakpoint: 1024,
        settings: {
          dots: false,
        }
      },
      ]
  })
});

$('.large-issue-carousel_slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
  var activeSlide = nextSlide + 1;
  var slide_child = ':nth-child('+ activeSlide +')';
  $('.large-issue-carousel_slider' + slide_child + '')
  .find('.slick-dots li'+ slide_child +'');
});

$(document).ready(function(){
		$('.news-events-teaser-slider').each(function() {
			$(this).slick({
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        //centerMode: true,
        appendArrows: $(this).parents('.news-events-teaser').find('.news-events-teaser-slider-arrows-container'),
        prevArrow: '<button class="prev-news-events-teaser-slider-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12"><path d="M6.435.175l-5.5 5.5 5.5 5.5L7.5 10.11 3.814 6.424l18.686.001v-1.5L3.814 4.924 7.5 1.24 6.435.175z" fill="#fff" fill-rule="evenodd"/></svg></button>',
        nextArrow: '<button class="next-news-events-teaser-slider-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 12"><path d="M16.065.84l5.5 5.5-5.5 5.5L15 10.775l3.686-3.686L0 7.09v-1.5l18.686-.001L15 1.905 16.065.84z" fill="#fff" fill-rule="evenodd"/></svg></button>',
        responsive: [
          {
            breakpoint: 1205,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: true,
                // arrows: false
              }
            },
            {
            breakpoint: 830,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                // arrows: false
              }
            },
          ]
			});
		});
  });

$(document).ready(function(){
  $('.research-teaser-slider').slick({
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    // centerMode: true,
    appendArrows: '.research-teaser-slider-arrows-container',
    prevArrow: '.prev-research-teaser-slider-arrow',
    nextArrow: '.next-research-teaser-slider-arrow',
    responsive: [
      {
        breakpoint: 1205,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
            // arrows: false
          }
        },
        {
        breakpoint: 830,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            // arrows: false
          }
        },
      ]
  })
})

$(document).ready(function(){
	$('.testimonial-carousel__slider').slick({
		dots: true,
		arrows: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: true,
		appendArrows: '#testimonial-carousel-slider-arrows-container',
		prevArrow: '.prev-testimonial-carousel-slider-arrow',
		nextArrow: '.next-testimonial-carousel-slider-arrow',
		appendDots: jQuery('.gray-box'),
        adaptiveHeight: true
	})
})

$(document).ready(function(){
  $('.environmental-justice-slider-container').slick({
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendArrows: '#environmental-justice-slider-arrows-container',
    prevArrow: '.prev-environmental-justice-slider-arrow',
    nextArrow: '.next-environmental-justice-slider-arrow',
  })
})

if ($('.josh-js').length > 0) {
  window.josh = new Josh({
    initClass: "josh-js",
    offset: 0.2,
    animateInMobile: true,
    onDOMChange: true,
  });
}

$('#filter-box .submit-reset-buttons button, .filter-button').click(function() {
    $("#filter-box").slideToggle();
})

$(".btn-zoom").hover(function(){
  const wrapper = $(this).parents('.text-image-repeater__inner');
  const image = wrapper.find('.image img');
	if($(image).hasClass('active')){
		$(image).removeClass('active');
	} else {
		$(image).addClass('active')
	}
});
// $(window).scroll(function () {
// 	if ($(document).scrollTop() > $('.large-successes-row').offset().top && $(document).scrollTop() < $('#stay-in-the-know').offset().top) {
// 		$('.cta .image img').addClass("active");
// 	} else {
// 		$('.cta .image img').removeClass("active");
// 	}



// });

// $('.cta .btn').hover(function(){
// 	$('.cta .image img').removeClass("active");
// });


$(window).on("load",function() {
  $(window).scroll(function() {
    var windowBottom = $(this).scrollTop() + $(this).innerHeight();
    $(".fade-in-view").each(function() {
      var objectBottom = $(this).offset().top + $(this).outerHeight();
      if (objectBottom < windowBottom) {
        $(this).addClass('in-view');
      }
    });
  }).scroll();
});


// sticky header function
$(document).ready(function() {
    let header = $('header.site-header');
    let offsetTop = header.offset().top;
    let headerHeight = header.outerHeight();
})

/*** search expand & collapse ***/
const searchTrigger = document.querySelector('.header-search-trigger');
const searchContainer = document.querySelector('.header-search-container');

// open search form when user clicks on search icon
searchTrigger.addEventListener('click', function(e) {
    e.preventDefault();
    searchContainer.classList.add('expand');
    e.stopPropagation();
});

// close form when user clicks outside of search form
document.body.addEventListener('click', function (e) {
    if (searchContainer.contains(e.target) === false && searchContainer.classList.contains('expand')) {
        searchContainer.classList.remove('expand');
    }
});

if (document.querySelector('.accordion__item')) {
    //Initialize Accordion
    Accordion();
}

if (document.querySelector('.onpage-nav-links')) {
    //Initialize OnPageNav
    OnPageNav();
}

if (document.querySelector('.custom-embed')) {
    //Initialize CustomEmbed
    CustomEmbed();
}

// load rellax only on desktop screen sizes
let rellax;
const rellaxLoader = {
    params: {
        speed: 0.75,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: false,
        center: true
    },

    load: function(){
        rellax = new Rellax('.rellax', this.params);
    },

    refresh: function(){
        if(typeof rellax !== 'undefined'){
            rellax.refresh();
        } else {
            this.load();
        }
    },

    detect: function(){
        let screenWidth = window.innerWidth;
        if(screenWidth > 1023){
            this.refresh();
        } else {
            if(typeof rellax !== 'undefined'){
                rellax.destroy();
            }
        }
    }
};
const detect = rellaxLoader.detect;
const initRellax = detect.bind(rellaxLoader);

document.addEventListener('DOMContentLoaded', function(){
    initRellax();
});

window.addEventListener("resize", debounce(function(e){
    initRellax();
}));

function debounce(fn){
    var timer;
    return function(e){
        if(timer){
            clearTimeout(timer);
        }
        timer = setTimeout(fn,100,e);
    };
}


const heroButton = $('.js-zoom-background');
if (heroButton.length > 0) {
  heroButton.on('mouseover', function() {
    const wrapper = $(this).parents('.homepage-hero');
    const image = wrapper.find('.img');
    image.addClass('is-active');
  })

  heroButton.on('mouseout', function() {
    const wrapper = $(this).parents('.homepage-hero');
    const image = wrapper.find('.img');
    image.removeClass('is-active');
  }); 
}

