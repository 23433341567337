import React, { useEffect, useState } from 'react'
import { searchArticles } from '../classes/ArticlesAPI'
import { Formik, Form } from 'formik'
import Pagination from './Pagination/Pagination';
import _ from 'lodash'

export default function ArticlesHub ({filters=[], articleType})
{
    const [articles, setArticles] = useState([])
    const [count, setCount] = useState(0)
    const [pages, setPages] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)

    let searchParams = {
        'article-type': articleType
    }

    useEffect(() => {
        (async () => {
            let response = await searchArticles(searchParams)
            let json = await response.json()
            setCount(json.data.count)
            setPages(json.data.max_pages)
            setPageNumber(json.data.current_page)
            setArticles(json.data.articles)
        })()
    }, []);

    async function handleFilterSubmit(opts){
        let args = {}
        _.each(opts, (option, key) => {
            args[key] = option
        })

        args['page'] = pageNumber;
        args['article-type'] = articleType;

        let response = await searchArticles(args)
        let json = await response.json()
        setCount(json.data.count)
        setPages(json.data.max_pages)
        setPageNumber(json.data.current_page)
        setArticles(json.data.articles)
    }

    return (
        <div id="articles">
            <Formik initialValues={{}} onSubmit={(values) => handleFilterSubmit(values)}>
                {({handleSubmit, values}) => (
                <Form>
                    <div className="news-hub mb-12">
                        <Articles articles={articles} filters={filters} values={values} count={count} pages={pages} pageNumber={pageNumber} setPageNumber={setPageNumber} handleSubmit={handleSubmit} />
                    </div>
                </Form>
                )}
            </Formik>
        </div>
    )
}

function Articles({articles, pages, pageNumber, setPageNumber, handleSubmit}){
    return (
        <div className="results">
            <div className="md:grid md:grid-cols-2 lg:grid-cols-3 gap-16">
                {articles.map(article => (
                    <Article key={article.ID} model={article} />
                ))}
            </div>
            <Paginate pages={pages} pageNumber={pageNumber} setPageNumber={setPageNumber} handleSubmit={handleSubmit} />
        </div>
    )
}

function Article({model}){
    return (
        <a href={model.link} className="article block mb-20 lg:mb-10">
            {(model.img_url &&
                <div className="article__image mb-6">
                    <img src={model.img_url} alt={model.title}></img>
                </div>
            )}
            <h6 className="type-preset-6 text-orange-300 font-bold mb-2">{model.type}</h6>
            <h3 className="article__title type-preset-5 font-display" dangerouslySetInnerHTML={{__html: model.title }} />
            <div className="type-preset-7 text-gray-300 font-display" dangerouslySetInnerHTML={{__html: model.date}} />
        </a>
    )
}

function Paginate({pages, pageNumber, setPageNumber, handleSubmit}){
    if(pages > 1){
        return (
            <div className='pagination news-hub mt-16'>
                <Pagination
                    total={pages}
                    prevText="prev"
                    nextText="next"
                    endSize={1}
                    midSize={4}
                    current={parseInt(pageNumber)}
                    onClickPage={(pageNum) => {
                        setPageNumber(pageNum);
                        handleSubmit();
                    }}
                />
            </div>
        )
    } else {return (<div />)}
}
