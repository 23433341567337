import $ from "jquery";

$(document).ready(function() {
  $('#resources-teaser .pagination a').click(function(e) {
    e.preventDefault();
    let $container = $(this).closest('#resources-teaser'),
        wrapper = $(this).closest('[data-current]'),
        currentPage = parseInt(wrapper.data('current')),
        pages = parseInt(wrapper.data('pages')),
        linkParent = $(this).parent(),
        toPage = null;
    if (linkParent.hasClass('next') || linkParent.hasClass('prev')) {
      if (linkParent.hasClass('next')) toPage = currentPage === pages ? currentPage : currentPage + 1;
      if (linkParent.hasClass('prev')) toPage = currentPage === 1 ? 1 : currentPage - 1;
    } else {
      toPage = parseInt($(this).data('number'));
    }
    // return if the same page
    if (toPage === currentPage) return;

    $container.find('[data-page]:not([data-page="'+toPage+'"])').css('display', 'none');
    $container.find('[data-page="'+toPage+'"]').css('display', 'block');
    wrapper.find('a').removeClass('current');
    wrapper.find('a[data-number="'+toPage+'"]').addClass('current');
    let offsetTop = $container.offset().top + 1;
    setTimeout(() => {
      $('html, body').stop().animate({
        scrollTop: offsetTop,
      }, 300);
    }, 200)

    wrapper.data('current', toPage);
  })
})
