import React, { useEffect, useRef, useState } from 'react'
import { searchArticles } from '../classes/ArticlesAPI'
import { Formik, Form, Field } from 'formik'
import TextInput from "./Fields/TextInput";
import _ from 'lodash'

export default function NewsHub ({filters=[]})
{
    const [articles, setArticles] = useState([])
    const [articleTypes, setArticleType] = useState([])
    const [count, setCount] = useState(0)
    const [pages, setPages] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)
    const [isOpened, setIsOpened] = useState(false)
    const [filtersOpen, setFiltersOpen] = useState(false)

    let searchParams = {};
    useEffect(() => {
        (async () => {
            let response = await searchArticles(searchParams)
            let json = await response.json()
            setCount(json.data.count)
            setPages(json.data.max_pages)
            setPageNumber(json.data.current_page)
            setArticles(json.data.articles)
            setArticleType(json.data.article_types)
        })()
    }, []);

    async function handleFilterSubmit(opts){

        let args = {}
        _.each(opts, (option, key) => {
            args[key] = option
        })

        args['page'] = pageNumber;

        let response = await searchArticles(args)
        let json = await response.json()
        setCount(json.data.count)
        setPages(json.data.max_pages)
        setPageNumber(json.data.current_page)
        setArticles(json.data.articles)
        setFiltersOpen(false);
    }

    function toggle() {
        setIsOpened(wasOpened => !wasOpened);
    }


    return (
        <div id="article-search" className={ filtersOpen ? 'filters-open' : 'filters-closed' }>
            <Formik initialValues={{}} onSubmit={(values) => handleFilterSubmit(values)}>
                {({handleSubmit, values, setFieldValue, handleReset}) => (
                <Form>
                    <div className="news-hub mb-12 mt-spacing-13 lg:mt-spacing-29">
                        <Filters options={articleTypes} handleSubmit={handleSubmit} values={values} handleReset={handleReset} articleTypes={articleTypes} toggle={toggle} isOpened={isOpened} count={count} setPageNumber={setPageNumber} />
                        <Search options={filters} setPageNumber={setPageNumber} handleSubmit={handleSubmit} />
                    </div>
                </Form>
                )}
            </Formik>
        </div>
    )
}

function Filters({options, handleSubmit, values, handleReset, setFiltersOpen, articleTypes, toggle, isOpened, count, setPageNumber}){
    return (
        <div className="issues-filter" data={isOpened ? "filter-box-is-open" : "filter-box-is-closed"}>
        <div className="issue-bar relative overflow-hidden">
            <div className="issue-bar-container px-0 max-w-1140 mx-auto bg-blue-500 text-ncel-white flex justify-between">
                <div className="issues-total py-spacing-6">
                    {articleTypes && articleTypes.length != 0 && (
                        <span className="type-preset-5">
                            Viewing All ({count})
                        </span>
                    )}
                </div>
                <button id="filter-button" type="button" onClick={toggle} className="filter-button flex items-center p-6 lg:px-4 lg:py-spacing-6">
                    <span className="type-preset-5 mr-4 hidden lg:block">Filter</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24">
                        <g fill="#FFF" fillRule="nonzero">
                            <path d="M12.25 3.09v1.5H.75v-1.5zm10 0v1.5h-5.5v-1.5zm-16 8v1.5H.75v-1.5zm16 0v1.5h-11.5v-1.5zm-15 8v1.5H.75v-1.5zm15 0v1.5h-10.5v-1.5z"/><path d="M17.418.922v5.5h-1.5v-5.5zm-11 8v5.5h-1.5v-5.5zm6 8v5.5h-1.5v-5.5z"/>
                        </g>
                    </svg>
                </button>
            </div>
            {/* {isOpened && ( */}
            <div id="filter-box" className="relative filter-box w-full">
                <div className="inner-container max-w-1140 mx-auto bg-blue-500 pb-10">
                    <h5 className="type-preset-5 text-ncel-white font-bold mb-spacing-4">Article Types</h5>
                    <div className="issue-type-checkboxes flex flex-wrap justify-start mb-spacing-9">
                    {Object.entries(options).map(checkbox => (
                        <label className="issue-type relative type-preset-7 text-ncel-white font-display flex items-center" key={checkbox[0]}>
                        <Field className="form-checkbox h-5 w-5 text-blue-100 border-2 border-blue-100 mr-2" type="checkbox" name="article-type" value={checkbox[1]['slug']} />
                        {checkbox[1]['name']}
                        </label>
                    ))}
                    </div>
                    <div className="submit-reset-buttons flex flex-col lg:flex-row justify-end pr-10">
                        <button type="button" className="type-preset-7 inline-block btn white-orange-btn mr-4" onClick={
                            () => {
                                setPageNumber(1);
                                handleSubmit();
                            }
                        }>Apply Filters</button>
                        <button type="button" className="type-preset-7 inline-block btn white-orange-btn" onClick={
                            () => {
                                setPageNumber(1);
                                handleReset();
                            }
                        }>Reset All Filters</button>
                    </div>
                </div>
            </div>
            {/* )} */}
            </div>
        </div>
    )
}

function Search({options, setPageNumber, handleSubmit, setFieldValue}){
    return (

        <div className="search-container relative">
            <div className="search-inner-container max-w-1140 mx-auto px-6 lg:pl-0 lg:pr-20 py-12 bg-gray-100">
            <div className="flex">
                <div className="w-full">
                    <TextInput name={'Search'} className="text-gray-300" placeholder="Search for an Article or Event" />
                </div>
                <button type="button" className="btn bg-orange-300 type-preset-7 font-bold text-ncel-white border border-orange-300 hover:bg-orange-500 hover:text-ncel-white focus:outline-none hover:outline-none hover:border-orange-500" onClick={handleSubmit}>Search</button>
            </div>
            </div>
        </div>
    )
}
