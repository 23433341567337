import React, { useEffect, useState } from 'react'
import { globalSearch } from '../classes/GlobalSearchAPI'
import { Formik, Form } from 'formik'
import Pagination from './Pagination/Pagination';
import _ from 'lodash'

export default function GlobalSearchResults (data=[])
{
    const [items, setItems] = useState([])
    const [count, setCount] = useState(0)
    const [pages, setPages] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)

    let searchParams = {'search': data.searchQuery};
    useEffect(() => {
        (async () => {
            let response = await globalSearch(searchParams)
            let json = await response.json()
            setCount(json.data.count)
            setPages(json.data.max_pages)
            setPageNumber(json.data.current_page)
            setItems(json.data.items)
        })()
    }, []);

    async function handleFilterSubmit(opts){

        let args = {'search': data.searchQuery}
        _.each(opts, (option, key) => {
            args[key] = option
        })

        args['page'] = pageNumber;

        let response = await globalSearch(args)
        let json = await response.json()
        setCount(json.data.count)
        setPages(json.data.max_pages)
        setPageNumber(json.data.current_page)
        setItems(json.data.items)

        window.scrollTo({ top: (document.querySelector('.search-results-wrapper').getBoundingClientRect().top + window.pageYOffset - 150), behavior: 'smooth' });
    }

    return (
        <div className="search-results-wrapper container mx-auto pb-spacing-25 lg:pb-spacing-29">
            <Formik initialValues={{}} onSubmit={(values) => handleFilterSubmit(values)}>
                {({handleSubmit}) => (
                <Form>
                    <Articles items={items} pages={pages} pageNumber={pageNumber} setPageNumber={setPageNumber} handleSubmit={handleSubmit} />
                </Form>
                )}
            </Formik>
        </div>
    )
}

function Articles({items, pages, pageNumber, setPageNumber, handleSubmit}){
    return (
        <div className="w-full lg:w-3/4">
            {items.map(item => (
                <Article key={item.ID} model={item} />
            ))}
            <Paginate pages={pages} pageNumber={pageNumber} setPageNumber={setPageNumber} handleSubmit={handleSubmit} />
        </div>
    )
}

function Article({model}){
    return (
<div className="result my-24 josh-js fade" data-josh-anim-name="fadeInDown" data-josh-duration="500ms">
    <h5 className="type-preset-6 text-orange-300 capitalize font-bold mb-4" dangerouslySetInnerHTML={{__html: model.category }} />
    <h3 className="type-preset-4 text-blue-500 mb-12" dangerouslySetInnerHTML={{__html: model.title }} />
    {model.excerpt &&
        <div className="basic-narrative mb-12" dangerouslySetInnerHTML={{ __html: model.excerpt }}/>
    }
    <a href={model.url} className="type-preset-7 inline-block mb-12 btn orange-btn">Learn more</a>
</div>

    )
}

function Paginate({pages, pageNumber, setPageNumber, handleSubmit}){
    if(pages > 1){
        return (
            <div className='pagination news-hub mt-10'>
                <Pagination
                    total={pages}
                    prevText="prev"
                    nextText="next"
                    endSize={1}
                    midSize={4}
                    current={parseInt(pageNumber)}
                    onClickPage={(pageNum) => {
                        setPageNumber(pageNum);
                        handleSubmit();
                    }}
                />
            </div>
        )
    } else {return (<div />)}
}
