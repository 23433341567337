import React from "react";
import { Field, useField, useFormikContext } from "formik";

export const TextInput = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <Field
      {...field}
      {...props}
      type="text"
      className="w-full h-full type-preset-7 font-bold text-gray-300 px-5 py-4 focus:outline-none"
      value={field.value || ''}
      onChange={val => {
        setFieldValue(field.name, val.target.value);
      }}
    />
  );
};

export default TextInput;
