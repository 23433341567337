import $ from "jquery";

$(window).on('load', function() {
    if ($('body.news-events').length) {
        $('.news-events-teaser').each(function (i, el) {
            let elHeight = $(el).innerHeight();
            let windowHeight = $(window).height();
            if (elHeight > windowHeight) {
                let newTop = (elHeight - windowHeight) * -1;
                $(el).css('top', newTop + 'px');
            }
        })
    }
})

