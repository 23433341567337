import $ from 'jquery';

export default function OnPageNav() {

	$(window).on('load', function () {

		const onPageNavLinks = () => {
			if ($('.onpage-nav-links').length) {
				// calculate site header height and add it to css var
				let calculateHeaderHeight = function () {
					let headerHeight = $('header.site-header').height();
					document.documentElement.style.setProperty('--header-height', headerHeight);
				}
				calculateHeaderHeight();
				$(window).on('resize', _.throttle(calculateHeaderHeight, 100));

				function menuPageNavFunc($container) {
					// Cache selectors
					let lastId,
						topMenu = $container,
						topMenuHeight = topMenu.outerHeight(),
						hh = document.documentElement.style.getPropertyValue('--header-height'),
						headerHeight = hh ? parseFloat(hh) : 0,
						// All list items
						menuItems = topMenu.find('a'),
						// Anchors corresponding to menu items
						scrollItems = menuItems.map(function () {
							let item = $($(this).attr('href'));
							if (item.length) {
								// save current position prop for item
								let originPos = item.css('position');
								// make element static and calculate offsetTop
								item.css('position', 'static');
								let offsetTop = item.offset().top;
								// return original position
								item.css('position', originPos);
								return {
									id: item.attr('id'),
									offsetTop: offsetTop,
									item: item
								};
							}
						});

					// scroll animation
					menuItems.unbind().bind('click', function (e) {
						e.preventDefault();
						let href = $(this).attr('href').replace('#', '');
						scrollItems.map(function (i, el) {
							if (el.id === href) {
								let offsetTop = el.offsetTop - (topMenuHeight + headerHeight);
								$('html, body').stop().animate({
									scrollTop: offsetTop,
								}, 300);
							}
						})
					});

					// set menu items active when on scroll
					let setItemsActive = function () {
						// Get container scroll position
						let fromTop = $(window).scrollTop() + topMenuHeight + headerHeight * 2;
						// Get id of current scroll item
						let cur = scrollItems.map(function (i, el) {
							if (el.offsetTop < fromTop)
								return {el: el.item, id: el.id, idx: i !== 0 ? i - 1 : 0};
						});

						// Get the id of the current element
						if (cur.length === 0) {
							menuItems.removeClass('active');
							lastId = null;
							return;
						}
						cur = cur[cur.length - 1];

						let id = cur.id && cur.el.length ? cur.id : '';

						if (lastId !== id) {
							lastId = id;
							// Set/remove active class
							menuItems.removeClass('active');
							topMenu.find('[href=\'#' + id + '\']').addClass('active');
							// if slick slider, make slide active
							if ($container.hasClass('slick-initialized')) {
								$container.slick('slickGoTo', cur.idx);
							}
						}
					}
					$(window).on('scroll', _.throttle(setItemsActive, 100));
				}

				menuPageNavFunc($('.onpage-nav-links .links-container'));

				// mobile carousel
				let onPageMobile = $('.onpage-nav-links .links-mobile-container');
				onPageMobile.on('init', function () {
					menuPageNavFunc(onPageMobile);
				});
				onPageMobile.not('.slick-initialized').slick({
					infinite: false,
					variableWidth: true,
					prevArrow: '<button class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12"><path d="M6.435.175l-5.5 5.5 5.5 5.5L7.5 10.11 3.814 6.424l18.686.001v-1.5L3.814 4.924 7.5 1.24 6.435.175z" fill="#FFF" fill-rule="evenodd"/></svg></button>',
					nextArrow: '<button class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 12"><path d="M16.065.84l5.5 5.5-5.5 5.5L15 10.775l3.686-3.686L0 7.09v-1.5l18.686-.001L15 1.905 16.065.84z" fill="#FFF" fill-rule="evenodd"/></svg></button>',
				});
			}
		}

		if ($('body').hasClass('bill-tracking') || $('body').hasClass('single-issue')) {
			setTimeout(function () {
				onPageNavLinks()
			}, 3000);
		} else {
			onPageNavLinks()
		}

	})
}