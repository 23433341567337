import $ from "jquery";

export default function CustomEmbed() {
    window.addEventListener('DOMContentLoaded', (event) => {
            var sectionEmbed = {
                ref: $('.custom-embed'),
                left: 0,
                right: 0
            };
            function calcLeftMargin( el, just_print ) {
                var new_left = el.ref.offset()['left'];
                if( new_left != 0 && !just_print ) {
                    var total_offset = el.left - new_left;
                    el.ref.css('margin-left', total_offset + 'px');
                    el.left = total_offset;
                }
            }
            function calcRightMargin( el, just_print ) {
                var new_right = $(window).width() - el.ref.width();
                if( new_right != 0 && !just_print ) {
                    var total_offset = el.right - new_right;
                    el.ref.css('margin-right', total_offset + 'px');
                    el.right = total_offset;
                }

            }
            function do_resize( el, just_print = false ) {
                calcLeftMargin( el, just_print );
                calcRightMargin( el, just_print );
            }

            var resize_timeout;
            do_resize(sectionEmbed);
            window.onresize = function(){
              clearTimeout(resize_timeout);
              resize_timeout = setTimeout(function() {
                    do_resize(sectionEmbed);
                }, 300);
            };
        });
}